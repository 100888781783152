/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React from "react"

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      d="M18.125 4.967a6.68 6.68 0 01-1.918.515 3.322 3.322 0 001.465-1.835 6.575 6.575 0 01-2.113.804 3.322 3.322 0 00-2.434-1.053 3.33 3.33 0 00-3.33 3.333c0 .257.031.515.082.763A9.465 9.465 0 013.01 4.008a3.307 3.307 0 00-.453 1.681c0 1.157.588 2.176 1.484 2.776a3.36 3.36 0 01-1.506-.424v.041c0 1.62 1.145 2.961 2.67 3.27a3.526 3.526 0 01-.877.113 4.43 4.43 0 01-.629-.05 3.336 3.336 0 003.114 2.31 6.675 6.675 0 01-4.135 1.424c-.28 0-.537-.01-.805-.041a9.431 9.431 0 005.115 1.494c6.125 0 9.477-5.075 9.477-9.479 0-.144 0-.289-.01-.434a7.173 7.173 0 001.67-1.722z"
      fill="currentColor"
    />
  </svg>
)
